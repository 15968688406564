import React from "react";

import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

export default function TicketNameBadge({ obj }) {

    return (
        <Badge bg="light" className="mt-2 text-dark badge-lg d-flex">
            {obj?.generalAdmission ? (
                <>{obj?.name}</>
            ) : (
                <>
                    <Stack>
                        <span className="text-muted caption fw-normal">Sec</span>
                        <span>{obj?.section}</span>
                    </Stack>
                    <Stack>
                        <span className="text-muted caption fw-normal">Row</span>
                        <span>{obj?.row}</span>
                    </Stack>
                    <Stack>
                        <span className="text-muted caption fw-normal">Seat</span>
                        <span>{obj?.seat}</span>
                    </Stack>
                </>
            )}
        </Badge>
    )
}