import React from 'react';
import { Link } from 'react-router-dom';

import { SearchList } from '../../SearchList';
import { LoadingContainer } from '../../LoadingContainer'

// dropdown
export default function SearchResultsContainer({ isLoading, query, queryResults }) {

    return (
        <div className="d-flex-column">
            <h1 className="normal--uppercase mb-4 text-left">Search results for "{query}"</h1>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {queryResults.length > 0 ? (
                        <>
                            <SearchList queryResults={queryResults} />
                            <Link to={`/search?query=${query}`} className="mt-4 btn btn-outline-light">
                                See all results
                            </Link>
                        </>
                    ) : (
                        <h1 className="normal">Sorry, there are no results matching your search. Please try again</h1>
                    )
                    }
                </>
            )}

        </div>
    );
}
