import React from 'react';

import { Hero } from './Hero'
import { FeatureCards } from './FeatureCards'
import { CTA } from './CTA'
import { SecondaryFeatureCards } from './SecondaryFeatureCards'
import { Testimonials } from './Testimonials';

import './homeWrapper.scss';

export default function HomeWrapper() {

    return (
        <section className="home-wrapper">
            <Hero />
            <FeatureCards />
            <Testimonials />
            <CTA />
            <SecondaryFeatureCards />
        </section>
    );
}
