import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import DetailsButton from "../DetailsButton/DetailsButton";
import { AddSubtractQuantity } from "../AddSubtractQuantity";
import "./GATickets.scss";

export default function GATickets({ checkout }) {
  const [open, setOpen] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  return (
    <ul>
      <GATicket
        open={open}
        setOpen={setOpen}
        detailsOpen={detailsOpen}
        setDetailsOpen={setDetailsOpen}
        quantity={quantity}
        setQuantity={setQuantity}
        checkout={checkout}
      />
    </ul>
  );
}

function GATicket({
  open,
  setOpen,
  detailsOpen,
  setDetailsOpen,
  quantity,
  setQuantity,
  checkout,
}) {
  useEffect(() => {
    if (!open) {
      setDetailsOpen(false);
    }
  }, [open, setDetailsOpen]);
  const handleDetailsButtonClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the parent button
    setDetailsOpen(!detailsOpen);
  };

  return (
    <li
      onClick={() => setOpen(!open)}
      aria-controls='ticket-collapse-text'
      aria-expanded={open}
      className={`vjx-ga-ticket ${open && '--active'}`}
    >
      <div className='vjx-ga-txt-col'>
        <span className='vjx-ticket-type'>GA (Standing Room)</span>
        <span className='vjx-ticket-price'>$ 30.00</span>
      </div>
      <div className='vjx-ga-txt-col'>
        <span className='vjx-ticket-pass'>Standard Pass</span>
        <span className='vjx-ticket-taxes'>Incl. Taxes & Fees</span>
      </div>
      <Collapse in={open}>
        <div id='ticket-collapse-text'>
          <div className='vjx-collapse-one'>
            <DetailsButton
              text={"Ticket details"}
              onClick={handleDetailsButtonClick}
              aria-controls='ticket-details-text'
              aria-expanded={detailsOpen}
            />
            <div className='vjx-quantity-button'>
              <AddSubtractQuantity
                whiteBG={true}
                quantity={quantity}
                setQuantity={setQuantity}
              />
              <Button onClick={(e) => checkout(e)} variant='primary' size='lg' className='m-0'>
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse in={detailsOpen}>
        <div id='ticket-details-text'>
          <div className='vjx-collapse-two'>
            <span>1 General Admission Ticket</span>
            <span>1 Highboy Table</span>
            <span>4 Chairs</span>
            <span>
              *Guests at table must purchase a General Admission Ticket to enter
              Venue.
            </span>
          </div>
        </div>
      </Collapse>
    </li>
  );
}
