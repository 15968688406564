import { NewCheckoutContainer } from "./NewCheckoutContainer";
import { ModalsProvider } from "./providers/ModalsProvider";

export default function NewCheckoutWrapper() {
  return (
    <ModalsProvider>
      <NewCheckoutContainer />
    </ModalsProvider>
  );
}
