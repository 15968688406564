import React, { Fragment, useState } from 'react';

import Button from 'react-bootstrap/Button';

import { BankAccountDetailsModal } from '../BankAccountDetailsModal';
import { Spinner } from '../../LoadingContainer/Spinner';

export default function LinkBankAccountBtn({ marginTop, createConnectedAccount, isSaving }) {
    const [
        show,
        setShow
    ] = useState(false);

    return (
        <Fragment>
            <Button
                onClick={createConnectedAccount}
                size="lg"
                className={`icon-button btn-plus ${marginTop && `m-${marginTop}`}`}
            >
                {isSaving ? (
                    <Spinner />
                ) : (
                    'Link bank account'
                )}
            </Button>

            {/* <BankAccountDetailsModal show={show} handleClose={handleClose} /> */}
        </Fragment>
    );
}
