import { ModalTop } from "../ModalTop";

import "./centerBottomModal.scss";

export default function CenterBottomModal({
  dialogRef,
  handleClose,
  title,
  blurred = false,
  children,
}) {
  const handleClick = (event) => {
    if (event.target === dialogRef.current) {
      handleClose();
    }
  };

  return (
    <dialog
      ref={dialogRef}
      onClick={handleClick}
      className={`dialog-base dialog-base${blurred && "--blurred"}`}
    >
      <ModalTop title={title} handleClose={handleClose} />
      <div className='cb-content'>{children}</div>
    </dialog>
  );
}
