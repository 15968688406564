import { Route, Routes } from 'react-router-dom';

import { RequireAuth } from './context/Authorization/useAuth';

import {
    HomePage,
    BrowsePage,
    SearchPage,
    LoginPage,
    NewGAPurchasePage,
    NewSeatedPurchasePage,
    NewCheckoutPage,
    VenuePage,
    MyEventsPage,
    EventDetailsPage,
    GuestPassDetailsPage,
    MyTransfersPage,
    MyListingsPage,
    MyCollectablesPage,
    SettingsPage,
    SettingsOptionsPage,
    PersonalDetailsPage,
    LoginSecurityPage,
    WithdrawInvoicesPage,
    InvoicePage,
    PaymentInformationPage,
    PrivacyPolicyPage,
    TermsConditionsPage,
    PurchasePolicyPage,
    CookiesPolicyPage,
    DisclaimerPage,
    WalletPage
} from './pages';
import { ScrollToTop } from './components';
import { CheckoutSuccessPage } from './pages/CheckoutSuccessPage';

/**
 * @description Handle all the routes
 */

const Router = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/browse" element={<BrowsePage />} />
                <Route path="/www" element={<WalletPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="e/:slug/:shortcode" element={<NewGAPurchasePage />} />
                <Route path="e/:slug/:shortcode/tickets" element={<NewSeatedPurchasePage />} />
                <Route path="checkout" element={
                    <RequireAuth>
                        <NewCheckoutPage />
                    </RequireAuth>
                } />
                <Route path="venue/:id" element={<VenuePage />} />
                <Route path="my-events" element={
                    <RequireAuth>
                        <MyEventsPage />
                    </RequireAuth>
                } />
                <Route path="checkout/checkout-success" element={
                    <RequireAuth>
                        <CheckoutSuccessPage />
                    </RequireAuth>
                } />
                <Route path="event-details/:orderId" element={
                    <RequireAuth>
                        <EventDetailsPage />
                    </RequireAuth>
                } />
                <Route path="guest-passes/:eventUUID" element={<RequireAuth><GuestPassDetailsPage /></RequireAuth>} />
                <Route path="my-transfers" element={<RequireAuth><MyTransfersPage /></RequireAuth>} />
                <Route path="my-listings" element={<RequireAuth><MyListingsPage /></RequireAuth>} />
                <Route path="my-collectables" element={<MyCollectablesPage />} />
                <Route path="settings" element={<RequireAuth><SettingsPage /></RequireAuth>}>
                    <Route index element={<SettingsOptionsPage />} />
                    <Route path="personal-details" element={<PersonalDetailsPage />} />
                    <Route path="login-security" element={<LoginSecurityPage />} />
                    <Route path="withdraw-invoices" element={<WithdrawInvoicesPage />} />
                    <Route path="withdraw-invoices/invoice/:id" element={<InvoicePage />} />
                    <Route path="payment-information" element={<PaymentInformationPage />} />
                </Route>
                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="terms-conditions" element={<TermsConditionsPage />} />
                <Route path="purchase-policy" element={<PurchasePolicyPage />} />
                <Route path="cookies-policy" element={<CookiesPolicyPage />} />
                <Route path="disclaimer" element={<DisclaimerPage />} />
            </Routes>
        </ScrollToTop>
    );
};

export default Router;
