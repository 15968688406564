import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Navigation } from "./components";

const ConditionalContainerNav = ({ children }) => {
  const location = useLocation();
  const shouldExclude = useMemo(() => {
    return (
      location.pathname.includes("/tickets") &&
      location.pathname.includes("/e/")
    );
  }, [location.pathname]);

  // Feels stupid doing this for one page but oh hey
  return (
    <>
      <Navigation seatedPurchasePage={shouldExclude} />
      <div className={shouldExclude ? "" : "container"} id='main-container'>
        {children}
      </div>
    </>
  );
};

export default ConditionalContainerNav;
