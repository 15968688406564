import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTickets } from "../../../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

import { formatCurrency } from "../../../../../utilities/helpers";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import "../getStatus.scss";
import "./getTickets.scss";
import { GATickets } from "../../GATickets";
import { useGAModals } from "../../../NewGAPurchaseWrapper/GAModalsProvider";

export default function GetTickets({ event, fixedMobile = false }) {
  const navigate = useNavigate();
  const { openCBModal } = useGAModals();
  const { tickets, listings } = useTickets();

  const [lowestPrice, setLowestPrice] = useState(null);

  // Hopefully there is a better way
  // event has atleast pricinglevels so perhaps can use that for tickets.tickets
  useEffect(() => {
    let tempLowestPrice = Infinity;
    if (event.pricingLevels) {
      tempLowestPrice = parseFloat(event.pricingLevels[1]?.price);
    }

    // Might be useless, since tickets is empty
    if (tickets) {
      console.log("Tickets: ", tickets)
      Object.values(tickets).map((ticket) => {
        if (
          !ticket.locked &&
          ticket.pricing &&
          ticket.pricing.totalDue < tempLowestPrice
        ) {
          tempLowestPrice = ticket.pricing.totalDue;
        }
      });
    }
    if (listings) {
      listings.forEach((listing) => {
        if (
          !listing.locked &&
          listing.pricing &&
          listing.pricing.ticketCostWithFeesAndTax < tempLowestPrice
        ) {
          tempLowestPrice = listing.pricing.ticketCostWithFeesAndTax;
        }
      });
    }
    setLowestPrice(tempLowestPrice);
  }, [tickets, listings]);

  const buyNow = (e) => {
    e.stopPropagation();
    const toPath = `/e/${event?.seoUrl}/${event?.shortCode}/tickets`;
    navigate(toPath);
  };

  return (
    <Fragment>
      <Card
        body
        className='card-xl card-xl--dark get_tickets_desktop get_desktop w-100'
      >
        <Card.Title as='h5'>Get Tickets 🎟️</Card.Title>
        <GATickets checkout={buyNow} />
      </Card>
      <div
        className={`get_tickets_mobile ${
          fixedMobile ? "get_mobile_fixed" : "get_mobile_normal"
        }`}
      >
        <div>
          {lowestPrice && <p>From {formatCurrency(lowestPrice)}</p>}
          <span>No hidden costs, no suprises!</span>
        </div>
        <Button
          onClick={() => openCBModal("Select Ticket")}
          variant='primary'
          size='lg'
          className='m-0'
        >
          Buy Now
        </Button>
      </div>
    </Fragment>
  );
}
