import { useState, Fragment } from "react";

import Button from "react-bootstrap/Button";

import soldout from '../../../../../../assets/icons/soldout.svg';

export default function SoldOutContent({ layout, icon }) {

    const [emailError, setEmailError] = useState(false);

    const getNotified = () => {
        setEmailError(!emailError);
    }

    const getFormLayout = () => {
        if (layout === 'desktop') {
            return (
                <>
                    <div className={`get_presale_inner ${emailError && 'incorrect'}`}>
                        <input className="presale_input" type="text" placeholder="Enter your email" />
                        <Button
                            onClick={getNotified}
                            variant="primary"
                            size="lg"
                            className="icon-button--white btn-notify mt-0">
                            Get Notified
                        </Button>
                    </div>
                    {emailError &&
                        <div className="presale_incorrect info-text info-text-danger info-text-md">
                            <p>You are already on the waiting list, please use another email.</p>
                        </div>
                    }
                </>
            )
        } else if (layout === 'mobile') {
            return (
                <div className="mob_layout">
                    <input className={`presale_input ${emailError && 'incorrect'}`} type="text" placeholder="Enter your email" />
                    {emailError &&
                        <div className="info-text info-text-danger info-text-md">
                            <p>You are already on the waiting list, please use another email.</p>
                        </div>
                    }
                    <Button
                        onClick={getNotified}
                        variant="primary"
                        size="lg"
                        className="icon-button--white btn-notify">
                        Get Notified
                    </Button>
                </div>
            )
        }
    }

    return (
        <Fragment>
            {icon && <img className="soldout_icon" src={soldout} alt='soldout' />}
            <h5 className="presale_heading">We’re sorry, the event is sold out. 😔</h5>
            <p className="presale_info">Enter your email below to get notified in case a ticket becomes available</p>
            {getFormLayout()}
        </Fragment>
    )
}