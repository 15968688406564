import { useEffect, useState } from "react";
import { BannerImage } from "../../components/BannerImage";
import { AdditionalInfo } from "../../components/AdditionalInfo";
import { MainDescription } from "../../components/MainDescription";
import { GetTickets } from "../../components/GetStatus/GetTickets";
import { GetPresale } from "../../components/GetStatus/GetPresale";
import { GetSoldout } from "../../components/GetStatus/GetSoldout";
import { AboutSection } from "../../components/AboutSection";
import { Lineup } from "../../components/Lineup";
import { VenueSection } from "../../components/VenueSection";

export default function GAPurchaseContainer({ event }) {
  const [eventStatus, setEventStatus] = useState("on_sale"); // ['forsale', 'presale', 'soldout']

  // Testing set event status based on query param 'status'
  useEffect(() => {
    const statusParam = event?.status;
    if (statusParam) {
      switch (statusParam) {
        case "on_sale":
          setEventStatus("on_sale");
          break;
        case "pre_sale":
          setEventStatus("pre_sale");
          break;
        case "sold_out":
          setEventStatus("sold_out");
          break;
        default:
          break;
      }
      return;
    }
  }, [event]);

  return (
    <div className='new-tickets-page purchaseflow-gradient'>
      <div className='sticky-content'>
        <BannerImage event={event} />
        <div className='tablet-desktop-only'>
          <AdditionalInfo
            MTDescription={"Securely stored in your account."}
            BPDescription={"Safe from bots and scalpers."}
          />
        </div>
      </div>

      <div className='tickets_col'>
        <MainDescription event={event} />
        {true && <GetTickets event={event} fixedMobile={true} />}
        {/* {true && <GetPresale fixedMobile={true} />} */}
        {/* {true && <GetSoldout fixedMobile={true} />} */}
        <AboutSection title='About' summary={event?.summary} />
        <Lineup attractions={event?.attractions} />
        <VenueSection event={event} />
        <div className='mobile-only'>
          <AdditionalInfo
            MTDescription={"Securely stored in your account."}
            BPDescription={"Safe from bots and scalpers."}
          />
        </div>
      </div>
    </div>
  );
}
