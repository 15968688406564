import React from 'react'

import "./flipCardModalBody.scss";

export default function FlipCardModalBody({ textContent }) {
    return (
        <div className='flipcardmodalbody'>
            <p>{textContent}</p>
        </div>
    )
}
