import React from 'react';

import Alert from 'react-bootstrap/Alert';

export default function Error({ type }) {
    const getErrorType = (type) => {
        switch (type) {
            case 'invalidPhone':
                return 'Phone number is not valid. Please try again';

            case 'phone':
                return 'Phone number is not valid or already exists. Please try again';

            case 'invalidPassword':
                return 'Your password has to be at least 6 characters. Please try again';

            case 'email':
                return 'Email does not match records. Please try again';

            case 'invalidEmail':
                return 'Please enter a valid email address';

            case 'code':
                return 'Code entered is incorrect. Please try again';

            case 'transfer':
                return 'You can\'t transfer tickets to yourself. Choose a different number and try again'

            default:
                return 'We\'re experiencing technical difficulties. Please try again later.';
        }
    };
    return (
        <Alert variant='danger' className="gap-3 mt-3">
            <div className="body">
                <p>{getErrorType(type)}</p>
            </div>
        </Alert>
    );
}
