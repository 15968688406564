import React from 'react';
import { useInView } from "react-intersection-observer";

import Stack from 'react-bootstrap/Stack'
import Card from 'react-bootstrap/Card'

export default function Testimonials() {

    // DEV make this better
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const testimonials = [
        {
            id: "1",
            text: "Blocktickets' 2-click checkout and zero-fraud protection were pivotal in our festival\'s success, enabling us to manage ticket sales effortlessly and expand to three new locations this year.",
            author: "Davis Parker",
            role: "Partner @ Thaw Out Fest"
        },
        {
            id: "2",
            text: "Blocktickets' virtual box office and 24/7 customer support were essential in our recovery from the pandemic, enabling us to not only restore but exceed our previous performance.",
            author: "Madison Mikulak",
            role: "General Manager @ Varsity Theatre"
        },
        {
            id: "3",
            text: "Blocktickets' new revenue from facility fees and expertise in event management has led to soaring revenues and more visitors than ever before.",
            author: "Mark Bagnetto",
            role: "Owner @ SouthPort Hall"
        }
    ]

    return (
        <section className='testimonials'>
            <div ref={ref}>
                <div className={`testimonials_inner_div info-container ${inView ? 'text_appear_two' : ''}`}>
                    <h1 className="display-4 fw-semi-bold mb-4 pb-2 text-center">What our partners say</h1>
                    <Stack gap={4} className='testimonials-container flex-lg-row justify-content-between align-items-center'>
                        {testimonials?.map(testimonial => (
                            <Card body className="d-flex-column justify-content-between">
                                <Card.Text as="blockquote">{testimonial?.text}</Card.Text>
                                <Stack>
                                    <Card.Text className="fw-semi-bold">{testimonial?.author}</Card.Text>
                                    <Card.Text className="text-muted caption">{testimonial?.role}</Card.Text>
                                </Stack>
                            </Card>
                        ))}
                    </Stack>
                </div>
            </div>
        </section>
    );
}
