import React, { Fragment } from 'react';

import { savePassApple, savePassGoogle } from '../../utilities/api';

export default function WalletPage() {

    const storeAppleWallet = async () => {
        const response = await savePassApple();

        console.log("Response: ", response)

        if (response.statusText == 'OK') {
            const blob = await response.data;
            console.log(`Received Blob: Type = ${blob.type}, Size = ${blob.size}`); // Log blob type and size

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'event.pkpass';
            document.body.appendChild(a);
            a.click();

            // Clean up
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 100);
        } else {
            console.error('Failed to download the pass.');
        }
    }

    const storeGoogleWallet = async () => {
        const response = await savePassGoogle();
        window.open(response.data, '_blank');
    }

    return (
        <Fragment>
            <section style={{ display: 'flex', gap: '10px' }}>
                <button onClick={storeGoogleWallet} style={{ padding: '10px 20px', backgroundColor: 'white', text: 'black' }}>GOOGLE</button>
                <button onClick={storeAppleWallet} style={{ padding: '10px 20px', backgroundColor: 'white', text: 'black' }}>APPLE</button>
            </section>
        </Fragment>
    );
}
