import { createContext, useContext, useEffect, useState } from "react";
import { getAvailableSectionsAndRows } from "../../../../../utilities/api";
import { useLocation, useNavigate } from "react-router-dom";

export const TicketContext = createContext({
  tickets: [],
  listings: [],
  generalAdmissionTicket: {},
  generalAdmissionCount: 0,
});

export const useTickets = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error("Context must be used within a TicketsProvider");
  }
  return context;
};

// Provides all state/functionality for ticket processing
export const TicketsProvider = ({
  tickets,
  listings,
  offers,
  event,
  code,
  children,
}) => {
  // Each type of selection has its own state
  // because we need different details from each to display + to buy
  const [selectedFromSeats, setSelectedFromSeats] = useState([]); // [{seatId, offer}]
  const [selectedFromGA, setSelectedFromGA] = useState([]); // [{ quantity, offer }]
  const [selectedFromQuickpicks, setSelectedFromQuickpicks] = useState(null); // { quantity, quickpick }

  const [totalCount, setTotalCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);


  useEffect(() => {
    // Calculate total count
    const seatedCount = selectedFromSeats.length;
    const gaCount = selectedFromGA.reduce((sum, item) => sum + item.quantity, 0);
    const newTotalCount = seatedCount + gaCount;

    // Calculate total price
    const seatedPrice = selectedFromSeats.reduce((sum, item) => sum + item.price, 0);
    const gaPrice = selectedFromGA.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    const newTotalPrice = seatedPrice + gaPrice;

    setTotalCount(newTotalCount);
    setTotalPrice(newTotalPrice);

    console.log("selectedFromSeats: ", selectedFromSeats);
    console.log("selectedFromGA: ", selectedFromGA);
    console.log("selectedFromQuickpicks: ", selectedFromQuickpicks);
  }, [selectedFromSeats, selectedFromGA, selectedFromQuickpicks]);

  const [quickpicks, setQuickpicks] = useState([]);
  const [isLoadingTickets, setIsLoadingTickets] = useState(true);

  // FILTERING
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const quantityParam = searchParams.get("quantity");
  const sortParam = searchParams.get("sort");

  const initialQuantity = quantityParam ? parseInt(quantityParam, 10) : 2;
  const intialSort = sortParam === "bestseat" ? "bestseat" : "price";

  const [sort, setSort] = useState(intialSort);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [hasLockedOffers, setHasLockedOffers] = useState(false);

  // Need to check if these exist in searchParams
  // then check min/max data -> set to min and max
  // shouldn't min/max data come from priceRange state in NewSeatedPurchaseWrapper component?
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);

  useEffect(() => {
    if (offers) {
      const hasLocked = offers.some((offer) => offer.accessCode !== null);
      setHasLockedOffers(hasLocked);
    }
  }, [offers]);

  useEffect(() => {
    const quantity = parseInt(searchParams.get("quantity"), 10) || 2;
    const sort = searchParams.get("sort") === "bestseat" ? "bestseat" : "price";

    const offerIdsFromUrl = searchParams.get("offers");
    const selectedOfferIds = offerIdsFromUrl
      ? offerIdsFromUrl.split(",").map((id) => parseInt(id, 10))
      : [];

    const priceRangeFromUrl = searchParams.get("price");
    const priceRange = priceRangeFromUrl
      ? priceRangeFromUrl.split(",").map((minOrMax) => parseInt(minOrMax, 10))
      : [0, 5000]; // Default price range if not provided

    // event.id, quantity, priceRange, selectedOfferIds
    if (!event) return;
    getQuickPicks(event.id, quantity, priceRange, selectedOfferIds);

    setQuantity(quantity);
    setSort(sort);
    setSelectedOfferIds(selectedOfferIds);
    setPriceRange(priceRange);
  }, [location.search]);

  const updateSearchParam = (key, value) => {
    searchParams.set(key, value);
    navigate(`${location.pathname}?${searchParams}`);
  };

  const getQuickPicks = async (eventId, quantity, priceRange, offerIds) => {
    setIsLoadingTickets(true);
    try {
      let res = await getAvailableSectionsAndRows(
        eventId,
        quantity,
        priceRange,
        offerIds
      );
      console.log("quickpicks: ", res.data);
      setQuickpicks(res.data);
    } catch (error) {
      console.error("Error fetching quickpicks:", error);
    } finally {
      setIsLoadingTickets(false);
    }
  };

  const selectQuickPick = (pick) => {
    console.log("selectQuickPick: ", { pick, quantity });
    setSelectedFromQuickpicks({ pick, quantity });
  };

  return (
    <TicketContext.Provider
      value={{
        tickets,
        listings,
        isLoadingTickets,
        offers,
        event,
        code,
        quickpicks,
        sort,
        quantity,
        hasLockedOffers,
        priceRange,
        updateSearchParam,
        selectedFromQuickpicks,
        selectQuickPick,
        selectedFromSeats,
        selectedFromGA,
        setSelectedFromSeats,
        setSelectedFromGA,
        selectedOfferIds,
        getQuickPicks,
        totalCount,
        totalPrice
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
