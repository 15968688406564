import React from 'react';

import Stack from 'react-bootstrap/Stack';

import { SearchItem } from './SearchItem';

import './searchList.scss';

export default function SearchList({ queryResults }) {

    return (
        <div id="search-list">
            <span className="caption--uppercase-label cat-label mb-1">Events</span>
            <Stack id="list" as="ul">
                {queryResults && queryResults.map((result, index) => {
                    return (
                        <SearchItem key={index} event={result} />
                    )
                })}
            </Stack>
        </div>
    );
}
