import { useLayoutEffect, useRef, useState } from "react"

import Card from 'react-bootstrap/Card'
import Button from "react-bootstrap/Button"

import './aboutSection.scss'

export default function AboutSection({ title, summary, bg = 'dark' }) {

    const aboutRef = useRef(null);

    const [open, setOpen] = useState(false);

    const [isClamped, setIsClamped] = useState(false);

    useLayoutEffect(() => {
        if (aboutRef.current) {
            const isClamped = aboutRef.current.scrollHeight > aboutRef.current.clientHeight;
            setIsClamped(isClamped);
        }
    }, [])

    if (!summary) return null;

    return (
        <Card body className={`about_section card-xl w-100 card-xl--${bg}`}>
            <Card.Title as="h5" className="card-title-sm card-title--uppercase">{title}</Card.Title>
            <Card.Text ref={aboutRef} className={`about_text ${!open && 'clamp_text'}`} key={open}>
                {summary}
            </Card.Text>
            {isClamped && <Button
                onClick={() => setOpen(!open)}
                variant="link" className="btn-show">
                {open ? 'See less' : 'See more'}
            </Button>}
        </Card>
    )
}