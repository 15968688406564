import Button from 'react-bootstrap/Button';
import close from '../../../../assets/icons/close.svg'
import "./modalTop.scss"

export default function ModalTop({ title, handleClose, isSeatmapModal = false }) {

    return (
        <div className={`vjx-top-bar ${isSeatmapModal && '--seatmapmodal'}`}>
            <h6>{title}</h6>
            <Button className="tb-close" variant="link" onClick={handleClose}>
                <img src={close} alt='close' />
            </Button>
        </div>
    )
}