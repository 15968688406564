import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Stack from 'react-bootstrap/Stack';

import { LoadingContainer } from '../../../LoadingContainer';

import './countdown.scss';

export default function Countdown({ scheduledStartDate }) {

    const [days, setDays] = useState()

    const [hours, setHours] = useState()

    const [minutes, setMinutes] = useState()

    const [seconds, setSeconds] = useState()

    const padWithZeros = num => {
        if (num < 10) {
            return `0${num}`
        } else {
            return num
        }
    }

    const startTimer = setInterval(() => {
        const now = moment()
        const ticketDate = moment(scheduledStartDate)
        const duration = moment.duration(ticketDate.diff(now));

        setDays(duration.days())
        setHours(padWithZeros(duration.hours()))
        setMinutes(padWithZeros(duration.minutes()))
        setSeconds(padWithZeros(duration.seconds()))
    }, 1000)

    useEffect(() => {
        startTimer
    }, [])

    useEffect(() => {
        // if countdown clock is finished
        if (days === 0 && hours === '00' && minutes === '00' && seconds === '00') {
            // refresh ticket page to refetch tickets with countdown is finished 
            clearInterval(startTimer)
            window.location.reload()
        }
    }, [seconds])

    return (
        <>
            {isNaN(days) ? (
                <LoadingContainer />
            ) : (
                <div id="countdown">
                    <p className='small'>Tickets will be on sale in</p>
                    <div id="countdown-timer">
                        <Stack direction='horizontal' as="ul" className='justify-content-around'>
                            <Stack as="li">
                                <span id="days" className='ticker'>{days}</span>
                                <span>Days</span>
                            </Stack>
                            <Stack as="li">
                                <span id="hours" className='ticker'>{hours}</span>
                                <span>Hours</span>
                            </Stack>
                            <Stack as="li">
                                <span id="minutes" className='ticker'>{minutes}</span>
                                <span>Minutes</span>
                            </Stack>
                            <Stack as="li">
                                <span id="seconds" className='ticker'>{seconds}</span>
                                <span>Seconds</span>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            )}
        </>
    );
}