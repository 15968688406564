import React, { useEffect, useState, useContext } from 'react';

import LoadingContext from '../../../context/Loading/Loading';

import { createBankAccount, getBankAccount, removeBankAccount } from '../../../utilities/api';

import { SettingsWrapper, BankAccount, PageLoadingContainer } from '../../../components';

export default function PaymentInformationPage() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [account, setAccount] = useState()

    const getAccount = () => {
        showLoading();
        getBankAccount()
            .then((res) => {
                setAccount(res.data)
                hideLoading();
            }).catch((err) => {
                console.error(err)
                hideLoading()
            });
    }

    const removeBank = () => {
        removeBankAccount({account}).then((res) => getAccount()).catch(err => console.error(err))
    }

    useEffect(() => {
        showLoading()
        getAccount()
    }, [])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <SettingsWrapper>
                    <div className="settings-heading">
                        <h1 className="settings-title">Payment information</h1>
                        <h2 className="settings-subtitle">
                            The money you make from selling your tickets will be issued to the bank account associated with this
                            account.
                        </h2>
                    </div>
                    <BankAccount account={account} getAccount={getAccount} createBankAccount={createBankAccount} removeBank={removeBank} />
                </SettingsWrapper>
            )}
        </>
    );
}
