import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'

import placeholder from '../../../../assets/placeholder.png';

import './bannerImage.scss';

export default function BannerImage({ event }) {
    return (
        <div className="abcbanner_image">
            <Card body className="banner_inner card-xl card-xl--dark">
                <Image className='banner_img event-image' src={event?.image?.url || placeholder} alt={`Cover art for ${event?.name} event`} />
            </Card>
        </div>
    )
}