import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { getOrder } from '../../utilities/api';
import { fullHeightContainer, removeFullHeightContainer, userDevice } from '../../utilities/helpers';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { Event } from '../Event';
import { MyTickets } from '../MyTickets';
import { MyTicketsSlider } from './MyTicketsSlider';
import { ActionBtns } from '../ActionBtns';
import { TicketModal } from '../TicketModal';

export default function EventDetailsWrapper({ orderId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [order, setOrder] = useState()

    const [
        show,
        setShow
    ] = useState(false);

    const [
        ticketAction,
        setTicketAction
    ] = useState('');

    const [
        ticketStatus,
        setTicketStatus
    ] = useState('');

    const [ticket, setTicket] = useState()

    const [deviceType, setDeviceType] = useState("");

    useLayoutEffect(() => {
        const el = document.querySelector('#main-container');

        fullHeightContainer(el);

        return () => {
            removeFullHeightContainer(el);
        };
    }, []);

    useEffect(
        () => {
            showLoading();
            getMyOrders();
            setDeviceType(userDevice)
        },
        [
            orderId
        ]
    );

    const getMyOrders = () => {
        getOrder(orderId).then((res) => {
            setOrder(res.data)
            hideLoading()
        }).catch((err) => {
            console.error(err)
            hideLoading()
        });
    }

    const handleShow = () => setShow(true);

    const handleClick = (action, ticket) => {
        handleShow();
        setTicketAction(action);
        setTicket(ticket)
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs full-height-wrapper">
                    <div className="section-heading-sm">
                        <h1>{deviceType && deviceType === 'Mobile' ? 'Tickets' : 'Event details'}</h1>
                        <BackButton />
                    </div>
                    {deviceType && deviceType === 'Mobile' && (
                        <>
                            <MyTicketsSlider order={order} handleClick={handleClick} />
                            <ActionBtns handleClick={handleClick} ticketStatus={ticketStatus} order={order} />
                        </>
                    )}
                    {deviceType && deviceType === 'Desktop' && (
                        <>
                            <Event event={order?.event} />
                            <MyTickets order={order} handleClick={handleClick} ticketStatus={ticketStatus} />
                        </>
                    )}
                    <TicketModal
                        ticketAction={ticketAction}
                        setTicketStatus={setTicketStatus}
                        show={show}
                        setShow={setShow}
                        ticket={ticket}
                        order={order}
                        getMyOrders={getMyOrders}
                    />
                </section>
            )}
        </>
    );
}
