import "./sheetTop.scss";
import chevronleft from "../../../../assets/icons/chevronleft.svg";

export default function SheetTop({ canGoBack = false, goBack = undefined }) {
  if (canGoBack) {
    return (
      <div className='vjx-sheettop-back'>
        <button onClick={goBack} className='vjx-td-btn'>
          <img src={chevronleft} alt='left arrow' />
        </button>
        <h5>Ticket details</h5>
      </div>
    );
  }
  return <h3 className='vjx-sheettop-noback'>Your selection</h3>;
}
