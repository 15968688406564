import { useRef } from "react";
import { SwiperSlide } from "swiper/react";

import Button from "react-bootstrap/Button";

import { Slider } from "../../../Slider";

import placeholder from '../../../../assets/placeholder.png';
import chevronleft from '../../../../assets/icons/chevronleft.svg';

import './viewSwiper.scss';

export default function ViewSwiper({ viewImage, smallButtons = false }) {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null)

    return (
        <div className="--tslider">
            <div className={`button-box ${smallButtons && "--smallbuttons"}`}>
                <Button variant="outline-light" className='btn--icon --left' ref={navigationPrevRef} aria-label="left arrow">
                    <img src={chevronleft} alt="left arrow" />
                </Button>
                <Button variant="outline-light" className="btn--icon --right" ref={navigationNextRef} aria-label="right arrow">
                    <img src={chevronleft} alt="left arrow" />
                </Button>
            </div>
            <Slider slidesPerView={1} navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef}>
                <SwiperSlide key={1} className="swiper-lazy">
                    <img src={viewImage || placeholder} alt="Seat view for this ticket" />
                </SwiperSlide>
                <SwiperSlide key={2} className="swiper-lazy">
                    <img src={viewImage || placeholder} alt="Seat view for this ticket" />
                </SwiperSlide>
            </Slider>
        </div>
    )
}