import { useContext, useEffect, useState, useLayoutEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import LoadingContext from "../../../../context/Loading/Loading";

import {
  getEvent,
  createOrder,
  getPaymentIntent,
  getCart,
} from "../../../../utilities/api";
import {
  toggleNavContent,
  toggleElement,
  fullHeightContainer,
  removeFullHeightContainer,
} from "../../../../utilities/helpers";

import Card from "react-bootstrap/Card";

import { BackButton } from "../../../BackButton";
import { PageLoadingContainer } from "../../../PageLoadingContainer";
import { PaymentMethod } from "../../components/PaymentMethod";
import { Terms } from "../../components/Terms";
import { BuyingThis } from "../../components/BuyingThis";
import { AdditionalInfo } from "../../components/AdditionalInfo";
import { Event } from "../../components/Event";
import { AboutSection } from "../../components/AboutSection";

import "../../purchaseFlow.scss";
import "./newCheckoutContainer.scss";
import { useModals } from "../providers/ModalsProvider";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function NewCheckoutContainer() {
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);
  const { openModal } = useModals();

  let show = true;

  const [clientSecret, setClientSecret] = useState("");

  const [status, setStatus] = useState("checkout");

  const [intentId, setIntentId] = useState("");

  const [event, setEvent] = useState();

  const [order, setOrder] = useState();

  const [errorShow, setErrorShow] = useState({
    error: false,
    msg: "",
    remaining: 0,
  });

  const [cart, setCart] = useState(null);

  useLayoutEffect(() => {
    document.getElementById("logo-link").style.pointerEvents = "none";
    const btns = document.querySelector(".desktop-btns");
    const nav = document.querySelector(".navbar-nav");
    const timer = document.getElementById("timer-container");

    toggleElement(timer, show);
    toggleNavContent(!show, btns, nav);

    const el = document.querySelector("#main-container");

    fullHeightContainer(el);

    return () => {
      toggleElement(timer, !show);
      toggleNavContent(show, btns, nav);
      removeFullHeightContainer(el);
      if (document.getElementById("logo-link"))
        document.getElementById("logo-link").style.pointerEvents = "auto";
    };
  }, []);

  useEffect(() => {
    showLoading();

    let cart = sessionStorage.getItem("cart");
    if (cart) cart = JSON.parse(cart);

    let eventId;

    console.log("cart:", cart);
    if (cart?.eventUUID) {
      eventId = cart.eventUUID;
    }

    console.log("EventID: ", eventId);
    getCart(eventId).then((res) => {
      eventId = res.data.eventUUID;
      console.log("getCartRes: ", res.data);
      setCart(res.data);
      let data = {
        ip: res.data.ipAddress,
        carted: true,
        cartTickets: res.data.tickets,
        totalFromCart: res.data.total,
        event: res.data.event,
      };
      console.log("get cart data: ", data);
      getPaymentIntent(data)
        .then((res) => {
          setClientSecret(res.data.client_secret);
          setIntentId(res.data.id);
          hideLoading();
        })
        .catch((err) => {
          console.error("err: ", err);
          // console.error(err)
          hideLoading();
        });
      getEvent(eventId)
        .then((res) => {
          setEvent(res.data);
        })
        .catch((err) => {
          console.error(err);
          hideLoading();
        });
    });

    return;

    let data = {
      ticket: cart.ticket,
      listing: cart.listing,
      ticketCount: cart.ticketCount,
      promoCode: cart.promoCode,
      offerUUID: cart.offer?.uuid,
      seatedRow: cart.seatedRow,
      seatedSection: cart.seatedSection,
      seatedQuantity: cart.seatedQuantity,
    };

    if (cart?.ticket?.free) {
      let order = {
        cart: cart,
        paymentIntentId: "0",
      };
      createOrder(order)
        .then((res) => {
          // Need better way to store order data
          sessionStorage.setItem("order", JSON.stringify(res.data));
          // Fake payment intent for free tickets
          setClientSecret(
            "pi_3Lj32nEjx5eLnToD1C9LQPI9_secret_ImFRee2TheUSer2BUY"
          );
          setStatus("successful");
          setOrder(res.data);
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          setErrorShow({
            error: true,
            msg: error.response?.data?.error?.message,
            remaining: error?.response?.data?.error?.details?.ticketsRemaining,
          });
        });
    } else {
    }
  }, []);

  useEffect(() => {
    // listen for order changes
  }, [order]);

  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  const addOns = [];

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Poppins", sans-serif',
      fontLineHeight: "1.4",
      fontSizeBase: "14px",
      fontSizeSm: "12px",
      fontWeightNormal: "500",
      borderRadius: "12px",
      colorPrimary: "#5ab6f8",
      colorBackground: "#FFFFFF",
      colorText: "#23262F",
      colorWarning: "#EF466F",
      colorDanger: "#EF466F",
      colorTextSecondary: "#777e90",
      spacingUnit: "12px",
      spacingGridRow: "24px",
    },
    rules: {
      ".Input": {
        boxShadow: "0px 0px 0px 2px #E6E8EC",
        padding: "12.250px 14.875px",
        lineHeight: "var(--fontLineHeight)",
      },

      ".Input:focus": {
        outline: "0",
        boxShadow: "0px 0px 0px 2px var(--colorPrimary)",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        boxShadow: "0px 0px 0px 2px #E6E8EC",
        padding: "12.250px 14.875px",
        border: "none",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        boxShadow: "0px 0px 0px 2px var(--colorPrimary)",
        backgroundColor: "var(--colorPrimary)",
      },
      ".Label": {
        fontWeight: "700",
        textTransform: "uppercase",
        color: "var(--colorTextSecondary)",
        marginBottom: "var(--spacingUnit)",
        lineHeight: "var(--fontLineHeight)",
      },

      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },

      ".Error": {
        marginTop: "var(--spacingUnit)",
      },
    },
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
    loader: "always",
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
  };

  if (status === "successful") {
    const timer = document.getElementById("timer-container");
    const btns = document.querySelector(".desktop-btns");
    const nav = document.querySelector(".navbar-nav");

    toggleNavContent(show, btns, nav);
    toggleElement(timer, !show);
    document.getElementById("logo-link").style.pointerEvents = "auto";
    if (document.getElementById("checkout-wrapper"))
      document
        .getElementById("checkout-wrapper")
        .classList.add("confirmation-padding");
  }

  return (
    <>
      {isLoading ? (
        <PageLoadingContainer
          text='Securing your tickets'
          subtext='You will be redirected to the payment page.'
          showLoader={false}
          icon='ticket'
        />
      ) : (
        <div className='new-checkout-page purchaseflow-gradient'>
          <div className='desktop-only'>
            <BackButton />
          </div>
          <div className='pfselect-wrapper'>
            <div className='tablet-desktop-only'>
              <Event
                event={event}
                openModal={() => openModal("Event Information", event)}
              />
            </div>
            <Card className='card-xl card-xl--dark great-wrapper'>
              <Card.Body className='wrapper-body'>
                <div className='card-body-heading g-texts'>
                  <Card.Title as='h5'>You're so close!</Card.Title>
                  <Card.Subtitle as='h6'>
                    Select your payment method and complete your purchase.
                  </Card.Subtitle>
                </div>
                <BuyingThis cart={cart} />
                <PaymentMethod
                  clientSecret={clientSecret}
                  stripePromise={stripePromise}
                  options={options}
                  status={status}
                  event={event}
                  addOns={addOns}
                  setStatus={setStatus}
                  intentId={intentId}
                  order={order}
                  errorShow={errorShow}
                  cart={cart}
                />
                <Terms />
                <AboutSection
                  title='Ticket information'
                  summary={event?.summary}
                  bg='light'
                />
                <AdditionalInfo
                  bg='light'
                  MTDescription={
                    "Securely stored in your account."
                  }
                  BPDescription={
                    "Safe from bots and scalpers."
                  }
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
