import { Fragment, useState } from "react";

import Button from "react-bootstrap/Button";
import { useTickets } from "../../../../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

export default function PresaleContent({ layout, withDescription }) {
  const { offers } = useTickets();
  const [incorrectKey, setIncorrectKey] = useState(false);

  const unlock = () => {
    setIncorrectKey(!incorrectKey);
  };

  const confirmAccessCode = (ticket, code) => {
    setIsSaving(true);
    let data = {};
    data["accessCode"] = code;
    data["ticket"] = ticket;
    confirmCode(data)
      .then((res) => {
        if (res.data) {
          handleClick("confirmation", ticket);
          setIsSaving(false);
        } else {
          // Invalid error would go here
          setInvalidCode("Invalid access code. Please try again");
          setIsSaving(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  const getFormLayout = () => {
    if (layout === "desktop") {
      return (
        <>
          <div className={`get_presale_inner ${incorrectKey && "incorrect"}`}>
            <input
              className='presale_input'
              type='text'
              placeholder='Enter access key'
            />
            <Button
              onClick={unlock}
              variant='primary'
              size='lg'
              className='icon-button--white btn-unlock mt-0'
            >
              Unlock
            </Button>
          </div>
          <PresaleInputError incorrectKey={incorrectKey} />
        </>
      );
    } else if (layout === "mobile") {
      return (
        <div className='mob_layout'>
          <input
            className={`presale_input ${incorrectKey && "incorrect"}`}
            type='text'
            placeholder='Enter access key'
          />
          <PresaleInputError incorrectKey={incorrectKey} />
          <Button
            onClick={unlock}
            variant='primary'
            size='lg'
            className='icon-button--white btn-unlock'
          >
            Unlock
          </Button>
        </div>
      );
    }
  };

  const PresaleOfferComponent = ({ name, description }) => {
    return (
      <div className='presale-offer'>
        <h5 className='presale_heading'>{name} 🔒</h5>
        <p className='presale_info'>
          Enter an unlock key to get full access to the inventory
        </p>
        {getFormLayout()}
        {withDescription && description && (
          <div className='presale_desc'>
            <h6>DESCRIPTION</h6>
            <p>
              {description}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      {offers
        .filter((offer) => offer?.accessCode !== null)
        .map((offer) => (
          <PresaleOfferComponent
            key={offer.id}
            name={offer.name}
            description={offer.description}
          />
        ))}
    </Fragment>
  );
}

function PresaleInputError({ incorrectKey }) {
  if (incorrectKey) {
    return (
      <div className='presale_incorrect info-text info-text-danger info-text-md'>
        <p>Sorry, the key entered is incorrect. Please try again</p>
      </div>
    );
  }

  return null;
}
