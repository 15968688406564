import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

import placeholder from "../../../../assets/placeholder.png";

import "./buyingThis.scss";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function BuyingThis({ cart, goToWalletButton = false }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("cart: ", cart);
  }, [cart]);

  const goToMyWallet = () => {
    navigate("/my-events");
  };

  if (!cart || !cart.tickets || cart.tickets.length === 0) {
    return null;
  }

  const groupTickets = () => {
    // Create a map of offerId to offer for quick lookup
    const offerMap = new Map(cart.offers.map((offer) => [offer.id, offer]));

    return cart.tickets.reduce((acc, ticket) => {
      const key = `${ticket.section}-${ticket.row}`;
      if (!acc[key]) {
        acc[key] = {
          tickets: [],
          offerNames: new Set(),
        };
      }
      acc[key].tickets.push(ticket);

      // Find the correct offer using associatedOfferId
      const offer = offerMap.get(ticket.associatedOfferId);
      if (offer) {
        acc[key].offerNames.add(offer.name);
      }

      return acc;
    }, {});
  };

  const groupedTickets = groupTickets();

  return (
    <div className='buying-this-container'>
      {Object.entries(groupedTickets).map(([key, group]) => (
        <TicketCard
          key={key}
          ticketGroup={group.tickets}
          offerNames={Array.from(group.offerNames)}
        />
      ))}
      {goToWalletButton && (
        <Button
          variant='primary'
          size='lg'
          onClick={goToMyWallet}
          className='mt-3'
        >
          Go to my wallet
        </Button>
      )}
    </div>
  );
}

const TicketCard = ({ ticketGroup, offerNames }) => {
  const getSeats = (seats) => {
    if (seats.length === 0) return "";
    seats.sort((a, b) => a - b);
    const ranges = [];
    let start = seats[0];
    let prev = seats[0];

    for (let i = 1; i <= seats.length; i++) {
      if (i === seats.length || seats[i] !== prev + 1) {
        ranges.push(start === prev ? start.toString() : `${start}-${prev}`);
        start = seats[i];
      }
      prev = seats[i];
    }

    return `Seat${seats.length > 1 ? "s" : ""}: ${ranges.join(", ")}`;
  };

  const { section, row, generalAdmission } = ticketGroup[0];

  return (
    <Card className='card-xl card-xl--light buying-this'>
      <Card.Body className='d-flex-column gap-3'>
        <div className='d-flex gap-3'>
          <img src={placeholder} alt='Seat view for this ticket' />
          <div className='ticket-info'>
            <div className='col'>
              <Stack direction='horizontal' gap={2} className='flex-wrap'>
                {offerNames.map((name, index) => (
                  <Badge key={index} bg='default' className='badge-primary'>
                    {name}
                  </Badge>
                ))}
              </Stack>
              <Stack className='gap-md-2 align-items-start mt-2'>
                <Card.Title
                  as='h5'
                  className={`ticket-loc ${
                    generalAdmission ? "ticket" : "seat"
                  } ticket-loc`}
                >
                  {generalAdmission
                    ? "General Admission"
                    : `Sec ${section} • Row ${row}`}
                </Card.Title>
                <Card.Text className='mobile-only'>
                  {ticketGroup.length} Tickets
                </Card.Text>
                {generalAdmission ? (
                  <Card.Text className='tablet-desktop-only'>This is a standing area 🧑‍🤝‍🧑</Card.Text>
                ) : (
                  <Card.Text className='tablet-desktop-only'>
                    {getSeats(ticketGroup.map((t) => Number(t.seat)))}
                  </Card.Text>
                )}
              </Stack>
            </div>
            <div className='tablet-desktop-only'>
              <div className='right-side'>
                <div className='ticket-count-info'>
                  <span className='ticket-count'>{ticketGroup.length}</span>
                  <span className='ticket-text'>{ticketGroup.length > 1 ? 'Tickets' : 'Ticket'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
