import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import LoadingContext from '../../context/Loading/Loading';

import { getEvents, getVenues } from '../../utilities/api';
import { sortBy } from '../../utilities/helpers';

import { PageLoadingContainer, BrowseWrapper, Footer } from '../../components';

export default function BrowsePage() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [events, setEvents] = useState()
    const [venues, setVenues] = useState()

    useEffect(() => {
        showLoading()
        getEvents()
            .then((res) => {
                setEvents(sortBy(res.data))

                getVenues()
                    .then((res) => {
                        setVenues(res.data)
                        hideLoading()
                    })
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
            })
            .catch((err) => {
                console.error(err)
                hideLoading();
            })
    }, [])

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="keywords" content={`Looking for something exciting?  Search here for to find events in your area on Blocktickets.`} />
                <title>Find tickets to interesting events in your area | Blocktickets</title>
                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`${window.location.host}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={"Find tickets to events in your area | Blocktickets"} />
                <meta property="og:description" content={`Looking for something exciting?  Search here for to find events in your area on Blocktickets.`} />
                <meta property="og:image" content={`https://blocktickets.nyc3.cdn.digitaloceanspaces.com/logo.png`} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={`${window.location.host}`} />
                <meta property="twitter:url" content={`${window.location.host}`} />
                <meta name="twitter:title" content={`Find tickets to events in your area | Blocktickets`} />
                <meta name="twitter:description" content={`Looking for something exciting?  Search here for to find events in your area on Blocktickets.`} />
                <meta name="twitter:image" content={`https://blocktickets.nyc3.cdn.digitaloceanspaces.com/logo.png`} />
            </Helmet>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <BrowseWrapper events={events} venues={venues} />
                    <Footer />
                </>
            )}
        </Fragment>
    );
}
