import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../../../../context/Loading/Loading';

import { getMyEvents } from '../../../../../utilities/api';

import { PageLoadingContainer } from '../../../../PageLoadingContainer';
import { Invoice } from './Invoice'

export default function InvoiceWrapper({ id }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [details, setDetails] = useState()

    const [invoice, setInvoice] = useState()

    const [ticketAmount, setTicketAmount] = useState(0);

    const [totalAmount, setTotalAmount] = useState(0);

    const [facilityFee, setFacilityFee] = useState(0);

    const [serviceFee, setServiceFee] = useState(0);

    const [processingFee, setProcessingFee] = useState(0);

    const [tax, setTax] = useState(0);

    const [totalFees, setTotalFees] = useState(0);

    useEffect(() => {
        showLoading();
        getMyEvents()
            .then((res) => {
                setDetails(res.data)
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    useEffect(() => {
        if (!details) return

        let currInvoice = details.find(d => d.id == id)

        setInvoice(currInvoice)

        if (currInvoice?.details?.listing) {
            setTicketAmount(currInvoice?.details?.listing?.askingPrice)
            setTotalAmount(currInvoice?.details?.listing?.total)
            setFacilityFee(currInvoice?.details?.listing?.pricing?.ticketFacilityFee * currInvoice?.details?.listing?.quantity)
            setServiceFee(currInvoice?.details?.listing?.pricing?.serviceFees * currInvoice?.details?.listing?.quantity)
            setProcessingFee(currInvoice?.details?.listing?.pricing?.paymentProcessingFee * currInvoice?.details?.listing?.quantity)
            setTax(currInvoice?.details?.listing?.pricing?.taxPerTicket * currInvoice?.details?.listing?.quantity)
            setTotalFees(currInvoice?.details?.listing?.pricing?.totalFeesWithProcessingFee * currInvoice?.details?.listing?.quantity)
        } else if (currInvoice?.details?.tickets?.length > 0) {
            setTicketAmount(currInvoice?.details?.ticket?.pricing?.ticketCost)
            setTotalAmount(currInvoice?.details?.ticket?.pricing?.ticketCost * currInvoice?.details?.ticketCount)
            setFacilityFee(currInvoice?.details?.ticket?.pricing?.ticketFacilityFee * currInvoice?.details?.ticketCount)
            setServiceFee(currInvoice?.details?.ticket?.pricing?.serviceFees * currInvoice?.details?.ticketCount)
            setProcessingFee((currInvoice?.details?.ticket?.pricing?.processingFee + currInvoice?.details?.ticket?.pricing?.serviceFees + currInvoice?.details?.ticket?.pricing?.chargeBackProtection) * currInvoice?.details?.ticketCount)
            setTax(currInvoice?.details?.ticket?.pricing?.salesTax * currInvoice?.details?.ticketCount)
            setTotalFees((currInvoice?.details?.ticket?.pricing?.processingFee + currInvoice?.details?.ticket?.pricing?.serviceFees + currInvoice?.details?.ticket?.pricing?.chargeBackProtection + currInvoice?.details?.ticket?.pricing?.ticketFacilityFee + currInvoice?.details?.ticket?.pricing?.salesTax) * currInvoice?.details?.ticketCount)
        }
        hideLoading()
    }, [details])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <Invoice invoice={invoice} ticketAmount={ticketAmount} totalAmount={totalAmount} facilityFee={facilityFee} serviceFee={serviceFee} processingFee={processingFee} tax={tax} totalFees={totalFees} />
            )}
        </>
    );
}
