import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import "./sheetBottom.scss";
import { Spinner } from "../../../LoadingContainer/Spinner";

export default function SheetBottom({ VSButton = false, VSClick = undefined, selectedTicketCount, totalTicketsPrice, checkout, checkingAvailability }) {
  return (
    <div className='vjx-sb'>
      <div className='vjx-sbt'>
        <div className='vjx-sbl'>
          <span className='vjx-ss1'>Subtotal</span>
          <span className='vjx-ss2'>{selectedTicketCount} Tickets</span>
        </div>
        <span className='vjx-ss3'>${totalTicketsPrice}</span>
      </div>
      <Stack
        direction='horizontal'
        className='btn-group-flex mt-2 btn-group-flex-stretch'
      >
        {VSButton && (
          <Button onClick={VSClick} variant='outline-light' size='lg'>
            View selection
          </Button>
        )}
        <Button
          disabled={checkingAvailability}
          type='primary'
          className='icon-button'
          size='lg'
          onClick={checkout}
        >
          {checkingAvailability ? (
            <>
              {!VSButton && "Checking availability "}
              <Spinner />
            </>
          ) : (
            "Checkout"
          )}
        </Button>
      </Stack>
    </div>
  );
}
