import React from 'react'

import { Hero } from './Hero';
import { Venues } from './Venues';
import { Events } from './Events';

export default function BrowseWrapper({ events, venues }) {

    return (
        <>
            <main>
                <Hero />
                <Events events={events} />
                <Venues venues={venues} />
            </main>
        </>
    );
}
