import Card from 'react-bootstrap/Card'

import placeholder from '../../../../assets/placeholder.png';

import './lineup.scss';

export default function Lineup({ attractions }) {

    if (attractions?.length < 1) return null;

    return (
        <section className="abclineup w-100">
            <Card body className="card-xl card-xl--dark">
                <Card.Title as="h5" className="card-title-sm card-title--uppercase">Who’s playing</Card.Title>
                <ul className='lineup_container'>
                    {attractions?.map((attraction, index) => (
                        <Performer key={index} name={attraction.name} src={attraction?.artwork?.url} />
                    ))}
                </ul>
            </Card>
        </section>
    )
}

function Performer({ name, src }) {
    return (
        <li className='performer'>
            <img src={src || placeholder} alt={`Portrait of ${name}`} />
            <h4 className='small small-bold m-0'>{name}</h4>
        </li>
    )
}