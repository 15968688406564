import { formatCurrency } from "../../../../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

import placeholder from "../../../../../../../assets/placeholder.png";

import { useSecondModal } from "../../../../providers/SecondModalProvider/SecondModalProvider";
import "./purchaseOption.scss";
import { useTickets } from "../../../../providers/TicketsProvider/TicketsProvider";

// Yeah need2find a way to combine Ticket & BuyingThis
export default function PurchaseOption({
  pick,
  openCBModal,
  setViewImage
}) {
  const { selectQuickPick } = useTickets();
  const { openModal } = useSecondModal();

  let isGeneralAdmission;
  let ticketPrice;
  let totalTicketPrice;
  let offerName;
  let maxQuantity;


  let getTicketImage = () => {
    let link =
      false == "Theater"
        ? `https://blocktickets-development.nyc3.digitaloceanspaces.com/thalia/$ticket.screenshot`
        : placeholder;
    return link;
  };

  const getActiveDiscountName = (offer) => {
    if (!offer.offer_discounts || offer.offer_discounts.length === 0) {
      return null;
    }
  
    const now = new Date();
    const timeLimitedDiscounts = offer.offer_discounts.filter(
      discount => discount.type === 'time-limited' && new Date(discount.expires) > now
    );
  
    if (timeLimitedDiscounts.length === 0) {
      return null;
    }
  
    // Sort discounts by start date (assuming there's a 'starts' field; if not, we'll need to adjust this)
    timeLimitedDiscounts.sort((a, b) => new Date(a.starts) - new Date(b.starts));
  
    return timeLimitedDiscounts[0].name;
  };

  const offer = pick.offers[0];
  // console.log("pick: ", pick)
  isGeneralAdmission = pick?.type === "GA" ? true : false;
  ticketPrice = pick?.price;
  offerName = getActiveDiscountName(offer) || offer.name; // Make this less error prone
  totalTicketPrice = ticketPrice;
  maxQuantity = pick.availableCount;

  const showTicketView = (e) => {
    e.stopPropagation();

    // The following controls modal title AND rendering of modal content
    setViewImage(getTicketImage());
    openCBModal("Your view");
  };

  const clickTicketGroup = () => {
    selectQuickPick(pick);
    // do something more clever in the future
    openModal();
  };

  return (
    <li className={`select_ticket`}>
      <button onClick={clickTicketGroup} role='button' className='ticket-inner mt-0'>
        <div onClick={showTicketView} className='img-wrap'>
          <img
            className='ticket-view'
            src={getTicketImage()}
            alt='Seat view for this ticket'
          />
        </div>
        <div className='ticket-info'>
          <div className='ticket-info-c1'>
            <Stack className='gap-md-2 flex-grow-1 align-items-start'>
              <Badge bg='default' className='badge-primary'>
                {offerName}
              </Badge>
              <h3 className={`ticket-seat seat`}>
                {isGeneralAdmission
                  ? `General Admission ${pick?.sectionId}`
                  : `Sec ${pick?.sectionId} \u2022 Row ${pick?.rowId}`}
              </h3>
            </Stack>
            {/* {minQuantity} - {maxQuantity} */}
            {(true || maxQuantity) && (
              <p className='ticket-quantity'>
                {2} - {pick.availableCount} Tickets
              </p>
            )}
          </div>
          <p className='ticket-info-c2'>
            <span className='ticket-price'>
              {formatCurrency(totalTicketPrice)} each
            </span>
            <span className='d-block ticket-fees'>
              Incl. Taxes<span className=' tablet-desktop-only'> & Fees</span>
            </span>
          </p>
        </div>
      </button>
    </li>
  );
}
