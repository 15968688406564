import { useEffect } from "react";
import { GAModalsProvider } from "./GAModalsProvider";
import GAPurchaseContainer from "./GAPurchaseContainer/GAPurchaseContainer";
import "../purchaseFlow.scss";
import "./newGAPurchaseWrapper.scss";
import { TicketsProvider } from "../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

export default function NewGAPurchaseWrapper({
  event,
  tickets,
  listings,
  generalAdmissionCount,
  generalAdmissionTicket,
  resaleTickets,
}) {
  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  return (
    <TicketsProvider
      tickets={tickets}
      listings={listings}
      generalAdmissionCount={generalAdmissionCount}
      generalAdmissionTicket={generalAdmissionTicket}
      resaleTickets={resaleTickets}
    >
      <GAModalsProvider event={event}>
        <GAPurchaseContainer event={event} />
      </GAModalsProvider>
    </TicketsProvider>
  );
}
