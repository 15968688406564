import { Fragment } from "react";

import Card from "react-bootstrap/Card";

import { SoldOutContent } from "./SoldOutContent";

import '../getStatus.scss'
import './getSoldout.scss';

export default function GetSoldout({ icon, fixedMobile = false }) {
    return (
        <Fragment>
            <Card body className={`${!fixedMobile && 'select_get_soldout'} card-xl card-xl--dark get_presale_desktop get_desktop w-100`}>
                <SoldOutContent layout={'desktop'} icon={icon} />
            </Card>
            <div className={`${fixedMobile ? 'get_mobile_fixed' : ' align-items-center text-center justify-content-center get_mobile_normal'} get_presale_mobile`}>
                <SoldOutContent layout={'mobile'} icon={icon} />
            </div>
        </Fragment>
    )
}