import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime, formatCurrency, getTimezoneDate, timezones } from '../../../../../utilities/helpers';

export default function InvoiceRow({ invoice }) {

    let timezone = timezones(invoice?.event?.venue?.timezone)

    const [ticketCount, setTicketCount] = useState(0);

    useEffect(() => {
        if (invoice?.details?.listing) {
            setTicketCount(invoice?.details?.listing?.quantity)
        } else if (invoice?.details?.details) {
            setTicketCount(invoice.status == "completeFromTransfer" ? invoice?.tickets.length : invoice?.details?.details?.ticketCount)
        } else if (invoice?.details) {
            setTicketCount(invoice.status == "completeFromTransfer" ? invoice?.tickets.length : invoice?.tickets?.length)

        }
    }, [])

    return (
        <tr>
            <th scope="row">{formatDateTime(getTimezoneDate(invoice?.createdAt, timezone))}</th>
            <td>{invoice?.event?.name} {invoice?.type === 'resale' && ' - Resale'}</td>
            <td>{ticketCount} {ticketCount?.length > 1 ? 'Tickets' : 'Ticket'}</td>
            <td>{formatCurrency(invoice?.total)}</td>
            <td>
                <Link to={`invoice/${invoice.id}`}>Download</Link>
            </td>
        </tr>
    );
}
