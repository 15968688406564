import { Elements } from "@stripe/react-stripe-js";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Checkout } from "./Checkout";

import "./paymentMethod.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PaymentMethod({
  clientSecret,
  stripePromise,
  options,
  status,
  event,
  addOns,
  setStatus,
  intentId,
  errorShow,
  cart,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (status === "successful") {
      navigate("checkout-success");
    }
  }, [status]);
  
  return (
    <Card className='card-xl card-xl--light checkout-payment'>
      <Card.Body className='w-100'>
        <div className='card-form'>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <Checkout
                event={event}
                addOns={addOns}
                setStatus={setStatus}
                intentId={intentId}
                cart={cart}
              />
            </Elements>
          )}
          {/* ARE WE USING ? */}
          <Modal
            className='checkout-modal'
            show={errorShow.error}
            animation={false}
            centered
            keyboard={false}
            backdrop='static'
          >
            <Modal.Body>
              <div className='modal-body-heading'>
                <h1 className='modal-body-heading-title'>
                  Limited Ticket Availability
                </h1>
              </div>
              <p>
                Please adjust your ticket selection{" "}
                <strong>{errorShow.remaining}</strong> tickets remaining.
              </p>
              <Stack className='btn-group-flex'>
                <Button size='lg' onClick={() => navigate(-1)}>
                  Go Back
                </Button>
              </Stack>
            </Modal.Body>
          </Modal>
        </div>
      </Card.Body>
    </Card>
  );
}
