import React, { Fragment, useState } from 'react';

import Col from 'react-bootstrap/Col';

import { Payment } from './Payment';
import { CheckoutModal } from './CheckoutModal';
import { CheckoutBottom } from './CheckoutBottom';

export default function Checkout({ event, addOns, setStatus, intentId, cart }) {
    let order = sessionStorage.getItem('order');
    if (order) order = JSON.parse(order);

    // Modal State
    const [
        modalType,
        setModalType
    ] = useState();

    const [
        modalError,
        setModalError
    ] = useState();

    const [
        show,
        setShow
    ] = useState(false);

    const handleShow = () => setShow(true);

    const handleClick = () => {
        setModalType('leave');
        handleShow();
    };

    const paymentDeclined = (err) => {
        setModalType('declined');
        setModalError(err);
        handleShow();
    };

    const checkValid = (e) => {
        let paymentButton = document.getElementById('payment-btn');
        let paymentButtonDisabled = document.getElementById('payment-btn-disabled');

        if (e && e.complete) {
            paymentButton.classList.add('d-flex');
            paymentButton.classList.remove('d-none');
            paymentButtonDisabled.classList.add('d-none');
        }
        else {
            paymentButton.classList.add('d-none');
            paymentButtonDisabled.classList.remove('d-none');
            paymentButtonDisabled.classList.add('d-block');
        }
    };

    return (
        <Fragment>
            <Col>
                {/* TBE 
                {addOns.length > 0 && (
                    <section id="addOns">
                        <AddOns />
                    </section>
                )} */}

                <section>
                    <Payment checkValid={checkValid} />
                </section>
                <CheckoutBottom
                    setStatus={setStatus}
                    intentId={intentId}
                    paymentDeclined={paymentDeclined}
                    cart={cart}
                />
            </Col>

            <CheckoutModal
                modalType={modalType}
                setModalType={setModalType}
                show={show}
                setShow={setShow}
                modalError={modalError}
            />
        </Fragment>
    )
}