import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/user';

import { verifyUser, verifiyCode, createNewUser, validEmail } from '../../utilities/api';
import { emailPatternMatch, namePatternMatch } from "../../utilities/helpers";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

import { PhoneNumberInput } from './../PhoneNumberInput';
import { Error } from '../Error';
import { BackButton } from './../BackButton';
import { Spinner } from '../LoadingContainer/Spinner'

import './loginSignupForm.scss';

export default function LoginSignupForm() {

    const { setAuthenticated } = useContext(UserContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [
        step,
        setStep
    ] = useState(0);

    const [choice, setChoice] = useState('phone-number');

    const [
        code,
        setCode
    ] = useState('');

    const [
        hasError,
        setHasError
    ] = useState(false);

    const [
        phoneNumber,
        setValue
    ] = useState();

    const [
        formValid,
        setFormValid
    ] = useState(false);

    const [
        firstName,
        setFirstName
    ] = useState('');

    const [
        lastName,
        setLastName
    ] = useState('');

    const [
        email,
        setEmail
    ] = useState('');

    const [
        resendDisabled,
        setResendDisabled
    ] = useState(false)

    const [isEmailValid, setIsEmailValid] = useState(true)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    // reset error when inputs are changed
    useEffect(
        () => {
            setHasError(false);
        },
        [
            phoneNumber,
            email,
            code
        ]
    );

    // reset error when email changes
    useEffect(
        () => {
            setIsEmailValid(true);
        },
        [
            email
        ]
    );

    useEffect(
        () => {
            if (firstName && lastName && email) {
                setFormValid(true);
            } else { setFormValid(false) }
        },
        [
            firstName,
            lastName,
            email
        ]
    );

    const handleGoBack = () => {
        let curStep = step;
        setStep(--curStep);
    };

    const validNumber = () => {
        return phoneNumber && isValidPhoneNumber(phoneNumber)
    }

    function setVal(e) {
        const num1 = document.getElementById('num1');
        const num2 = document.getElementById('num2');
        const num3 = document.getElementById('num3');
        const num4 = document.getElementById('num4');

        if (num1.value) num2.focus();
        if (num1.value && num2.value) num3.focus();
        if (num1.value && num2.value && num3.value) num4.focus();
        if (num1.value && num2.value && num3.value && num4.value) {
            const code = Number(`${num1.value}${num2.value}${num3.value}${num4.value}`);
            setCode(code);
            verifyUserCode(code);
        }
    }

    // submit phone number or email 
    function submit() {
        setIsSaving(true)
        if ((validNumber() || email) && !hasError) {
            let data = {
                data: {
                    phoneNumber: choice == 'email' ? '' : phoneNumber,
                    email
                }
            };
            verifyUser(data)
                .then((res) => {
                    setIsSaving(false)
                    setStep(1);
                })
                .catch((err) => {
                    setIsSaving(false)
                    setHasError(true);
                    console.error(err);
                });
        } else {
            setHasError(true)
            setIsSaving(false)
        }
    }

    function verifyUserCode(code) {
        let data = {
            data: {
                code
            }
        };
        verifiyCode(data)
            .then((res) => {
                if (res.status === 200) {

                    let from = '/';

                    // either from state or session 
                    if (location.state?.from) {
                        from = location.state?.from?.pathname;
                    }
                    else {
                        from = sessionStorage.getItem('location')
                    }
                    AuthService.setUser(res.data);
                    setAuthenticated(res.data);
                    // Send them back to the page they tried to visit when they were
                    // redirected to the login page. Use { replace: true } so we don't create
                    // another entry in the history stack for the login page.  This means that
                    // when they get to the protected page and click the back button, they
                    // won't end up back on the login page, which is also really nice for the
                    // user experience.
                    navigate(from, { replace: true });
                }
                else if (res.status === 203) {
                    setStep(2);
                }
            })
            .catch((err) => {
                setHasError(true);
                console.error(err);
            });
    }

    const submitForm = () => {
        setIsSaving(true)
        let data = {
            data: {
                email,
                firstName,
                lastName,
                phoneNumber
            }
        };
        createNewUser(data).then((res) => {
            if (res.status === 200) {
                setIsSaving(false)
                AuthService.setUser(res.data);
                navigate('/');
            }

            if (res.status === 226) {
                setIsSaving(false)
                setFormValid(false)
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: 'Email currently in use.'
                })
            }
        });
    };

    const submitEmailRequest = (e) => {
        let data = {
            data: {
                email
            }
        }
        validEmail(data).then(res => {
            if (res.data === 404) setHasError(true)
            if (res.data === 200) {
                setHasError(false)
                submit()
            }
        }).catch((err) => console.error(err))
    }

    const validatePattern = (val) => {
        const isMatch = emailPatternMatch(val)
        setIsEmailValid(isMatch)
    }

    const resend = () => {
        submit()
        setResendDisabled(true)
        setAlert({
            show: true,
            variant: 'success',
            message: 'Verification Code has been resent.'
        })
    }

    return (
        <Row className="spacer-md" id="login-signup-container">
            <Col md={4}>{step > 0 && <BackButton handleGoBack={handleGoBack} />}</Col>
            <Col md={6} className="form-container d-flex-column">
                {alert.show &&
                    <>
                        <Alert variant={alert.variant} className="mb-4" onClose={() => setAlert({ show: false, variant: '', message: '' })} dismissible>
                            <p>{alert.message}</p>
                        </Alert>
                    </>
                }
                {step === 0 && (
                    <Fragment>
                        <div className="heading">
                            <h1 className="fs-md">Login</h1>
                            <h2 className="normal text-muted fw-normal m-0">
                                The future of ticketing is here
                            </h2>
                        </div>
                        {choice == 'phone-number' ? (
                            <>
                                <div className="step-desc">
                                    <h3 className="title">Verify your mobile number</h3>
                                    <h4 className="subtitle">
                                        Select your country and enter your mobile number. You'll receive an
                                        access code via text message. If you don’t have an account, we will
                                        automatically create one for you.
                                    </h4>
                                </div>
                                <Form.Group controlId="phone-number">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setValue} hasError={hasError} />
                                </Form.Group>
                                <Button
                                    size="lg"
                                    className={`icon-button ${!isSaving ? 'btn-next' : ''} `}
                                    disabled={!phoneNumber || hasError || isSaving}
                                    onClick={submit}>
                                    {isSaving ? (
                                        <Spinner />
                                    ) : (
                                        'Validate'
                                    )}
                                </Button>
                                <Form.Text><p>Don't have access to your phone?</p> <p><Button variant="link" onClick={() => setChoice('email')}>Click here</Button> to use your email to login.</p></Form.Text>
                            </>
                        ) : (
                            <>
                                <div className="step-desc">
                                    <h3 className="title">Verify your email address</h3>
                                    <h4 className="subtitle">
                                        Please enter your email below and we will send you the security code there.
                                    </h4>
                                </div>
                                <Form.Group className="form-group" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={(e) => validatePattern(e.target.value)}
                                        className={(email && hasError || !isEmailValid) ? 'error-border' : ''}
                                    />
                                    {email && hasError && isEmailValid && <Error type="email" />}

                                    {email && !isEmailValid && !hasError && <Error type="invalidEmail" />}
                                </Form.Group>

                                <Button
                                    size="lg"
                                    className={`icon-button ${!isSaving ? 'btn-next' : ''} `}
                                    disabled={!email || hasError || !isEmailValid || isSaving}
                                    onClick={submitEmailRequest}>
                                    {isSaving ? (
                                        <Spinner />
                                    ) : (
                                        'Send'
                                    )}
                                </Button>
                            </>
                        )}
                    </Fragment>
                )}
                {step === 1 && (
                    <Fragment>
                        <div className="heading">
                            <h3 className="title mb-1">Enter 4 Digit Verification</h3>
                            <p className="subtitle">
                                Code was sent to <span className="text-primary">{phoneNumber ? phoneNumber : email} </span>
                                {email &&
                                    <span className="d-block">If you haven't received an email within 5 minutes check your spam folder.</span>
                                }
                            </p>
                        </div>
                        <Form.Group>
                            <Form.Label>Enter Code</Form.Label>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num1"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num2"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num3"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num4"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />
                            </Stack>
                        </Form.Group>
                        {hasError && <Error type="code" />}
                        <Form.Text>
                            Did not receive code? <Button variant="link" onClick={() => resend()} disabled={resendDisabled}>Resend Code</Button>
                        </Form.Text>
                    </Fragment>
                )}
                {step === 2 && (
                    <Fragment>
                        <div className="heading">
                            <h1 className="title">Let's Set Up your Profile</h1>
                        </div>
                        <Form className="d-flex-column">
                            <Form.Group className="form-group" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    required
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={(e) => validatePattern(e.target.value)}
                                    className={email && !isEmailValid ? 'error-border' : ''}
                                />
                                {
                                    email && !isEmailValid &&
                                    (<Error type="invalidEmail" />)
                                }
                            </Form.Group>

                            <Form.Group className="form-group" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    required
                                    pattern={namePatternMatch}
                                    value={firstName}
                                    onChange={(e) => setFirstName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="form-group" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    required
                                    pattern={namePatternMatch}
                                    value={lastName}
                                    onChange={(e) => setLastName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name
                                    )}
                                />
                            </Form.Group>
                            {hasError && <Error />}
                            <Form.Group className="form-group fw-semi-bold" controlId="upcoming-events">
                                <Form.Check type="checkbox" label="Opt out of receiving emails and texts for our upcoming events" />
                            </Form.Group>
                            <Button disabled={!formValid || !isEmailValid || isSaving} size="lg" onClick={submitForm} className='icon-button'>
                                {isSaving ? (
                                    <Spinner />
                                ) : (
                                    'Sign up'
                                )}
                            </Button>
                        </Form>
                    </Fragment>
                )}
            </Col>
        </Row>
    );
}
