import { useState } from 'react';

import { formatAddress } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { IconButton } from '../../../IconButton';

import placeholder from '../../../../assets/placeholder.png';

import './venueSection.scss';

export default function VenueSection({ event }) {

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(formatAddress(event?.venue));
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }

    return (
        <section className="abcvenue_section w-100">
            <Card body className="card-xl card-xl--dark w-100">
                <Card.Title as="h5" className="card-title-sm card-title--uppercase">Venue</Card.Title>
                <div className="venue_container">
                    {/* Create a hook -> if image does not work -> use placeholder */}
                    <img className='venue_img event-image' src={event?.venue?.image[0]?.url || placeholder} alt={`${event?.venue?.name} venue`} />
                    <div className="venue_info d-flex-column">
                        <h4 className='fs-md fs-md-bold lh-sm mb-1'>{event?.venue?.name}</h4>
                        <Stack direction="horizontal" className="justify-content-between" gap={2}>
                            <p>{formatAddress(event?.venue)}</p>
                            <OverlayTrigger show={isCopied} overlay={<Tooltip>Copied</Tooltip>} placement='top'>
                                <Button onClick={handleCopy} variant="default" className='p-0 m-0 btn-copy'>
                                    <svg aria-label="Copy" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 5H14C14.5523 5 15 5.44772 15 6V7H17V6C17 4.34315 15.6569 3 14 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H7V15H6C5.44772 15 5 14.5523 5 14V6C5 5.44772 5.44772 5 6 5Z" fill="#777E91" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M18 9H10C9.44772 9 9 9.44772 9 10V18C9 18.5523 9.44772 19 10 19H18C18.5523 19 19 18.5523 19 18V10C19 9.44772 18.5523 9 18 9ZM10 7C8.34315 7 7 8.34315 7 10V18C7 19.6569 8.34315 21 10 21H18C19.6569 21 21 19.6569 21 18V10C21 8.34315 19.6569 7 18 7H10Z" fill="#777E91" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </Stack>
                        <IconButton
                            variant='outline-light'
                            btn="directions"
                            link={`https://google.com/maps?q=${event?.venue?.address[0]?.address_1}+${event?.venue?.address[0]?.city}+${event?.venue?.address[0]?.state}`}
                            isExternal={true}
                            styles="align-self-start"
                        >
                            Directions
                        </IconButton>
                    </div>
                </div>
                <div className='venue_ac'>
                    <Accordion bsPrefix="accordion_container" as="ul">
                        <ExpandableItem title="Access by public transport" index={0} />
                        <ExpandableItem title="Parking" index={1} />
                        <ExpandableItem title="Accessibility" index={2} />
                    </Accordion>
                </div>
            </Card>
        </section>
    )
}

function ExpandableItem({ title, index }) {
    return (
        <Accordion.Item eventKey={index} as="li">
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
        </Accordion.Item>
    )
}