import React from 'react';
import Button from 'react-bootstrap/Button';
import "./ticketErrorModal.scss"

export default function TicketErrorModal({ dialogRef, handleClose }) {
    return (
        <dialog ref={dialogRef} className='ticket-error-dialog'>
            <div className="ticket-error-content">
                <h4>Selected tickets not available</h4>
                <span>The tickets/seats you have selected are no longer available, please make another selection.</span>
                <Button onClick={handleClose} type='primary' size='lg'>
                    Return to tickets list
                </Button>
            </div>
        </dialog>
    )
}