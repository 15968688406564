import { Fragment } from "react";

import Card from "react-bootstrap/Card";

import { PresaleContent } from "./PresaleContent";

import '../getStatus.scss'
import './getPresale.scss';

// Used in both GA and Seated Flows,,, 
export default function GetPresale({ withDescription = false, fixedMobile = false }) {
    return (
        <Fragment>
            <Card body className="card-xl card-xl--dark get_presale_desktop get_presale get_desktop w-100">
                <PresaleContent layout={'desktop'} withDescription={withDescription} />
            </Card>
            <div className={`get_presale_mobile get_presale ${fixedMobile ? 'get_mobile_fixed' : 'get_mobile_normal'}`}>
                <PresaleContent layout={'mobile'} withDescription={withDescription} />
            </div>
        </Fragment>
    )
}