import React, { useState } from 'react';

import { formatDateTime, formatShortAddress, getTimezoneDate, timezones } from '../../utilities/helpers'

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EventModal } from './EventModal';
import { InfoIcon } from '../InfoIcon'

import placeholder from '../../assets/placeholder.png';

import './event.scss';

export default function Event({ event, onClick, showBack }) {

    let timezone = timezones(event?.venue?.timezone)

    const [
        show,
        setShow
    ] = useState(false);

    const handleClose = () => setShow(false)

    const handleShow = () => setShow(true);

    return (
        <header className="event">
            <div className='container'>
                <Stack direction="horizontal" className="align-items-center gap-3">
                    {showBack && (
                        <Button variant="default" size="sm" className='btn--icon flex-shrink-0 mobile-only border-0 w-auto' onClick={onClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" role="img" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L9.91421 12L15.7071 6.20711C16.0976 5.81658 16.0976 5.18342 15.7071 4.79289C15.3166 4.40237 14.6834 4.40237 14.2929 4.79289L8.5 10.5858C7.71895 11.3668 7.71895 12.6332 8.5 13.4142L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071Z" fill="#777E91" />
                            </svg>
                        </Button>
                    )}
                    <div className="tablet-desktop-only">
                        <img
                            src={event?.image?.url || placeholder}
                            alt={`Cover art for ${event?.name} event`}
                            width="72"
                            height="72"
                            className="event-image"
                        />
                    </div>
                    <div className="d-flex flex-column details">
                        <h1 className="event-name fs-md fs-md-bold">{event?.name}</h1>
                        <Stack direction="horizontal">
                            <p>
                                <span>{formatDateTime(getTimezoneDate(event?.start, timezone), 'dateOnly')}</span>
                                {event?.display_start_time && (
                                    <>
                                        <span className='px-1'>at</span>
                                        <span>{formatDateTime(getTimezoneDate(event?.start, timezone), 'timeOnly')}</span>
                                    </>
                                )}
                                <span className='tablet-desktop-only venue'>
                                    {event?.venue?.name}, <span>
                                        {formatShortAddress(event?.venue)}
                                    </span>
                                </span>
                            </p>
                        </Stack>

                    </div>
                    <div className='flex-shrink-0'>
                        <Button
                            id="event-info"
                            variant="default"
                            size="sm"
                            className='d-flex align-items-center gap-2'
                            onClick={handleShow}>
                            <InfoIcon />
                            <span className="tablet-desktop-only">Event description</span>
                        </Button>
                        {event &&
                            <EventModal show={show} handleClose={handleClose} event={event} />
                        }
                    </div>
                </Stack>
            </div>
        </header>
    );
}