import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SeatmapModal from "./SeatmapModal/SeatmapModal";
import { useTickets } from "../TicketsProvider/TicketsProvider";

// Create the context
const SeatmapContext = createContext(undefined);

export const useSeatmap = () => {
  const context = useContext(SeatmapContext);
  if (!context) {
    throw new Error("Context must be used within a SeatmapProvider");
  }
  return context;
};

// Provide the functionality for displaying a seatmap inside a modal
export const SeatmapProvider = ({ children }) => {
  const dialogRef = useRef(null);
  const { event } = useTickets();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(null);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    setData(event?.seatmap?.mapping);
    setBackground(event?.seatmap?.background);
    console.log("Seatmap event: ", event);
    console.log("background: ", event?.seatmap?.background)
  }, [event]);

  const showSeatmap = () => {
    setDialogOpen(true);
    dialogRef.current.showModal();
  };

  const hideSeatmap = () => {
    setDialogOpen(false);
    dialogRef.current.close();
  };

  return (
    <SeatmapContext.Provider
      value={{ data, setData, showSeatmap, hideSeatmap, background }}
    >
      <SeatmapModal
        data={data}
        setData={setData}
        dialogRef={dialogRef}
        hideSeatmap={hideSeatmap}
        title={"Select your seats"}
        dialogOpen={dialogOpen}
      />
      {children}
    </SeatmapContext.Provider>
  );
};
