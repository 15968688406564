import Card from 'react-bootstrap/Card'

import './additionalInfo.scss'

export default function AdditionalInfo({ bg = 'dark', MTDescription, BPDescription }) {
    return (
        <Card body className={`card-xl additional_info card-xl--${bg}`}>
            <InformationContainer title='Mobile Tickets' description={MTDescription} />
            <InformationContainer title='Buyer Protection' description={BPDescription} />
        </Card>
    )
}

function InformationContainer({ title, description }) {
    return (
        <div className={`information_container ${title === 'Mobile Tickets' ? 'qrcode' : 'protection'} `}>
            <div className='ic_inner'>
                <Card.Title as="h5" className="card-title-sm">{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
            </div>
        </div>
    )
}