import React from 'react';

import { sortBy, formatDateTime, getStartDateFormatter, timezones, getTimezoneDate } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';

import { IconButton } from '../../IconButton';
import { LoadingContainer } from '../../LoadingContainer';

import placeholder from '../../../assets/placeholder.png'

import './eventsList.scss';

export default function EventsList({ venue }) {

    let timezone = timezones(venue?.timezone)

    return (
        <Stack as="ul">
            {venue && sortBy(venue.allEvents).map((event, index) => {
                return (
                    <Stack as="li" direction="horizontal" className="item" gap={3} key={index}>
                        <img
                            src={event?.image?.url || placeholder}
                            alt={`Cover art for ${event?.name} event`}
                            width="100"
                            height="100"
                            className="event-image"
                        />

                        <div className="event-info d-flex-column flex-lg-row gap-2 align-items-lg-center ">
                            <div className="event-name-date-wrapper">
                                <p className="event-name">{event?.name}</p>
                                <div>
                                    <span className="fw-semi-bold text-muted small">{formatDateTime(getTimezoneDate(event?.start, timezone), getStartDateFormatter(event))}</span>
                                </div>
                            </div>
                            <IconButton
                                link={`/e/${event?.seoUrl}/${event?.shortCode}`}
                                variant="outline-light"
                                btn="ticket--primary"
                                styles="align-self-start ms-lg-auto text-primary mt-0">
                                Get Tickets
                            </IconButton>
                        </div>
                    </Stack>
                )
            })}

            {/* <LoadingContainer /> */}
        </Stack>
    );
}
