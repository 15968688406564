import * as moment from 'moment';

import { timezones, formatDateTime, formatShortAddress, getTimezoneDate } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack'

import './mainDescription.scss'

export default function MainDescription({ event }) {

    let timezone = timezones(event?.venue?.timezone)

    return (
        <Card body className="main_description card-xl card-xl--dark w-100">
            {event?.ageMinimum && (
                <Badge bg="default" className="badge-outline badge-outline--dark desc_badge">
                    This event is 18+
                </Badge>
            )}
            <Stack gap={2}>
                <Card.Title as="h5" className='display-1 m-0'>{event?.name}</Card.Title>
                <Card.Link href="/" className='event-venue m-0 normal-lg fw-semi-bold'>{event?.venue?.name}, {formatShortAddress(event?.venue)}</Card.Link>
                <Card.Text className='normal-lg'>
                    {formatDateTime(moment(getTimezoneDate(event?.start, timezone)))}
                    {event?.display_start_time && <span className='time'>Doors {formatDateTime(moment(getTimezoneDate(event?.doorsOpen, timezone)), 'timeOnly')}</span>}
                </Card.Text>
                {/* IF we want end time {!event?.hide_end_date && (<span> - {formatDateTime(moment(getTimezoneDate(event?.end, timezone)), 'timeOnly')}</span>)} */}
            </Stack>
        </Card>
    )
}