import React, { Fragment } from "react";
import { QRCodeSVG } from 'qrcode.react';

import { formatShortDate, getStartDateFormatter, formatShortAddress, getTimezoneDate, timezones } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Stack from 'react-bootstrap/Stack';

import { TicketNameBadge } from "../TicketNameBadge";

import QRCode from '../../assets/noqr.png';

export default function QRTicketCard({ pass, ticket, event, order, handleClick }) {

    const obj = ticket || pass;
    const eventObj = order?.event || event;

    let timezone = timezones(eventObj?.venue?.timezone)

    const eventStart = getTimezoneDate(eventObj.start, timezone);
    const today = getTimezoneDate(new Date(), timezone);
    const showQR = today.isSame(eventStart, 'day');

    return (
        <Fragment>
            <Card body className="ticket-card">
                {showQR &&
                    <QRCodeSVG
                        width="217"
                        height="217"
                        className="mb-3 p-0"
                        value={obj.checkInCode}
                    />
                }

                {!showQR &&
                    <Image
                        src={QRCode}
                        className="mb-3"
                        alt={`QR Code for ${eventObj?.name} event`}
                    />
                }

                <div className="details d-flex-column">
                    <Card.Title as="h5" className="normal">{eventObj?.name}</Card.Title>
                    <p className="event-details">
                        {formatShortDate(getTimezoneDate(eventObj?.start, timezone), getStartDateFormatter(eventObj))}<span className="venue">{eventObj?.venue.name}</span><span className="loc">
                            {formatShortAddress(eventObj?.venue)}
                        </span>
                    </p>
                    <TicketNameBadge obj={obj} />
                    <Stack direction="horizontal" gap={3} className="mt-3 btn-group-flex">
                        <Button variant='outline-light' className="flex-grow-1" size="xs" onClick={() => handleClick('details', obj)}>Details</Button>
                    </Stack>
                </div>
            </Card>
        </Fragment>
    )
}